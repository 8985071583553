<!--侧边菜单栏组件-->
<template>
    <el-menu
            background-color="#404040"
            text-color="#F7FAF8"
            active-text-color="grey"
            style="height: 100%;"
            default-active="/Home"
            :collapse="isCollapse"
            :collapse-transition="false"
            router
    >
       <el-menu-item >
           <span slot="title" style="font-size: 22px;"><b>彪彪管理系统</b></span>
       </el-menu-item>
       <el-menu-item index="/Home">
           <i class="el-icon-s-home"></i>
           <span slot="title">首页</span>
       </el-menu-item>
       <el-menu-item index="/Profile">
           <i class="el-icon-warning"></i>
           <span slot="title">个人中心</span>
       </el-menu-item>

       <!--动态获取菜单-->
        <!-- <el-menu-item :index="'/'+item.menuclick" v-for="(item,i) in menu" :key="i">
            <i :class="item.menuicon"></i>
            <span slot="title">{{item.menuname}}</span>
        </el-menu-item> -->
        <el-menu-item index="/Supplier">
           <i class="el-icon-warning"></i>
           <span slot="title">供应商</span>
       </el-menu-item>
       <el-menu-item index="/SkuManage">
           <i class="el-icon-warning"></i>
           <span slot="title">Sku管理</span>
       </el-menu-item>
       <el-menu-item index="/ProcurementManager">
           <i class="el-icon-warning"></i>
           <span slot="title">采购管理</span>
       </el-menu-item>
       <el-submenu index="/skuInventory">
            <span slot="title">库存管理</span>
            <el-menu-item index="/purchaseWaitBeStorage">
                <i class="el-icon-warning"></i>
                <span slot="title">入库订单处理</span>
            </el-menu-item>
            <el-menu-item index="/skuInventoryItems">
                <i class="el-icon-warning"></i>
                <span slot="title">库存详情</span>
            </el-menu-item>
            <el-menu-item index="/skuStorehouseManager">
                <i class="el-icon-warning"></i>
                <span slot="title">仓库管理</span>
            </el-menu-item>
            <el-menu-item index="/skuInventoryHistory">
                <i class="el-icon-warning"></i>
                <span slot="title">库存操作记录</span>
            </el-menu-item>
            <el-menu-item index="/ExcelTable">
                <i class="el-icon-warning"></i>
                <span slot="title">Excel表格</span>
            </el-menu-item>
       </el-submenu>
       <el-submenu index="/salesManagement">
            <span slot="title">销售管理</span>
            <el-menu-item index="/Customer">
                <i class="el-icon-warning"></i>
                <span slot="title">客户管理</span>
            </el-menu-item>
            <el-menu-item index="/SalesSummaryManage">
                <i class="el-icon-warning"></i>
                <span slot="title">销售订单管理</span>
            </el-menu-item>
            <el-menu-item index="/SalesDetailsManage">
                <i class="el-icon-warning"></i>
                <span slot="title">销售订单详情</span>
            </el-menu-item>
            <el-menu-item index="/SellingPriceManage">
                <i class="el-icon-warning"></i>
                <span slot="title">销售价格管理</span>
            </el-menu-item>
       </el-submenu>
    </el-menu>
</template>

<script>
    export default {
        name: "Aside",
        data(){
            return {
                
            }
        },
        computed:{
            "menu":{
                get(){
                    return this.$store.state.menu
                }
            }
        },
        props:{
            isCollapse:Boolean  //导航栏菜单伸缩
        }
    }
</script>

<style scoped>

</style>